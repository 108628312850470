.main {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 75px 1fr;
}

.content {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.loading {
  @extend .content;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
}

.toolbar {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  margin: 10px 10px 0px 50px;

  &_item {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.chart {
  @extend .content;
}
