@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 150px;
  color: $primary-color;
}

.title {
  font-size: 50px;
  color: salmon;
  margin-bottom: 10px;
}

.text {
  font-size: 25px;
  color: $primary-color;
  margin-bottom: 50px;
}

.button {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $primary-color;

  &_text {
    margin-right: 2px;
    flex: auto;
    font-size: 15px;
    display: flex;
    justify-content: flex-end;
    color: $secondary-text-color;
  }
  &_icon {
    margin-left: 2px;
    flex: auto;
    font-size: 15px;
    display: flex;
    justify-content: flex-start;
  }
}
