.main {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr;
}

.header {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
}

.container {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  flex-direction: column;
}

.overlay {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
}

.toolbar {
  flex: auto;
  width: 100px;
  color: #adadad;
}

.map {
  flex: auto;
  position: relative;
}

.grid {
  flex: none;
  margin-top: 10px;
  height: 300px;
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.editor {
  height: 100%;
}
