@import "theme/palette.scss";

.main {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 1fr 25px;
}

.title {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(#fff, 0.1);
  font-size: 16px;
  color: rgba(#fff, 0.5);
  font-weight: 400;
}

.chart {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  padding: 10px 10px 0px 10px;
  overflow: hidden;
  }
  
.note {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
  padding: 0px 5px 5px 0px;
  flex: none;
  font-size: 11px;
  color: $secondary-text-color;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.overlay {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 4;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  font-size: 18px;
}
