@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  flex: auto;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-bottom: 5%;
  padding: 20px 30px 20px 30px;
  background-color: $background-color;
}

.logo {
  margin: 0px 0px 20px 0px;
  align-self: center;
}

.title {
  margin: 0px 0px 20px 0px;
  font-size: 20px;
  color: $secondary-text-color;
  align-self: center;
  font-weight: 500;
  letter-spacing: 1px;
}

.textbox {
  flex: 1;
  margin: 0px 0px 30px 0px;
}

.button {
  flex: 1;
  margin: 25px 0 10px 0px;
}

.footer {
  display: flex;
  justify-items: center;
  margin: 0px 0px 20px 0px;

  &_left {
    flex: 1;
    display: flex;
    color: $secondary-text-color;
  }

  &_switch {
    display: flex;
    margin-left: 10px;
  }
}

.forget {
  margin: 20px 0px 0px 0px;
  color: $secondary-text-color;
  text-align: center;
}

.error {
  margin: 20px 0px 0px 0px;
  color: lightcoral;
  text-align: center;
}

.seal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty{
  margin-top: 50px;
}
