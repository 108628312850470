.main {
    height: 100%;
  }
  
  .form {
    height: 100%;
    margin-left: 25px;
  }
  
  .indicator {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: auto;
  }
    