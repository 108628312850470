@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
}

.row {
  flex: auto;
  display: flex;
  flex-direction: column;
  //max-width: 100px;
}

.col {
  flex: auto;
  // height: 740px;
}

.loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: auto;
}

.tooltip {
  background-color: $menu-color;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px 12px 12px 12px;
  border-radius: 3px;
  width: 200px;

  ::before {
    border-top-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  ::after {
    border-bottom-color: white;
  }

  &_title {
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 6px;
  }

  &_row {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 600;
  }
}
