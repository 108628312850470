@import "theme/palette.scss";


.theme-ignorenode-treelist {
    //
    // rows
    .dx-treelist .dx-editor-cell {
      white-space: nowrap;
      border-bottom: 1px solid $line-color;
    }
}  