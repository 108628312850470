@import "theme/palette.scss";

.main {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 500px 60px;
}

.tabs {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.loading {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.buttons {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.form {
  height: 100%;
}

.customEditor {
  display: flex;
  margin-top: 25px;
  padding: 0px 0px 0px 10px;

  &_label {
    flex: none;
    width: 125px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &_selectbox {
    flex: auto;
    margin: 0px 25px 0px 0px;
  }

  &_container {
    flex: none;
    width: 55px;
    display: flex;
    margin-left: 5px;
    padding-top: 10px;

    &_checkbox {
      flex: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 10px;
    }

    &_icon {
      flex: none;
      font-size: 15px;
      color: $primary-color;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
