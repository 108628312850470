.main{
    height: 100%;
    display: flex;
}

.department
{
    flex: none;
    width: 350px;
}

.ignorenodes
{
    flex: auto;
    margin-left: 10px;
}

.form {
    flex: auto;
    display: flex;
    padding-bottom: 10px;
  }
  
  .form_fields {
    flex: auto;
    margin-left: 25px;
  }
