@import "theme/palette.scss";

.main {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px 5px 10px 5px;
  background-color: $menu-color;
  border-bottom: 1px solid rgba(#fff, 0.1);
  border-right: 1px solid rgba(#fff, 0.1);
}

.grid {
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 20fr 7fr;
  grid-template-rows: 1fr 2fr;
}

.line {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  width: 5px;
  border-radius: 2px;
}

.text {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  align-self: center;
}

.title {
  color: rgba(#fff, 0.5);
  align-self: center;
}

.icon {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
  align-self: center;
  justify-self: center;
  font-size: 25px;
}

.main_cursor{
  cursor: pointer;
}