@import "theme/palette.scss";

.main{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.activity{
    flex: auto;

}

.summary {
    flex: none;
    height: 350px;
    margin-top: 10px;
    background-color: $surface-color;
  
  }