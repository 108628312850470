@import "theme/palette.scss";


.data-map{
    .dx-toolbar-items-container {
        height: 40px;
        background-color:  $menu-color !important;
      }
}







// .klient_data_gis_main {
//   height: 100%;
//   display: flex;
//   flex-direction: column;

//   &_map {
//     flex: auto;
//     position: relative;
//   }

//   &_indicator {
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     //display: flex;
//     //justify-content: center;
//     //align-items: center;
//   }

//   &_grid {
//     flex: 0 0 300px;
//     height: 300px;
//   }
// }
