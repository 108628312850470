.main {
  height: 100%;
  display: flex;
}

.fund {
  flex: none;
  width: 350px;
  margin-left: 10px;
}

.container {
  flex: auto;
  margin-left: 10px;
}


