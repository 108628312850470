.main {
    height: 100%;
    display: flex;
  }
  
  .source {
    flex: none;
    width: 350px;
  }

  .container {
    flex: auto;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  
  