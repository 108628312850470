.main {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .fullgrid {
    flex: auto;
  }
  
  .tabs {
    flex: none;
    height: 350px;
    padding-top: 10px;
  }
  