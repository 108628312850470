.main {
  height: 100%;
}

.jobprogress {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background-color: rgba(0, 0, 0, 0.7);

  &_container {
    grid-row: 1/2;
    grid-column: 1/2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  &_circle {
    margin-top: -100px;
    grid-row: 1/2;
    grid-column: 1/2;
    text-align: center;
    align-self: center;
    justify-self: center;
    width: 75px;
    //color: #adadad;
  }

  &_text {
    grid-row: 1/2;
    grid-column: 1/2;
    margin-top: 0px;
    text-align: center;
    align-self: center;
    justify-self: center;
    //width: 75px;
    color: #adadad;
  }

  &_button {
    grid-row: 1/2;
    grid-column: 1/2;
    margin-top: 5px;
    text-align: center;
    align-self: center;
    justify-self: center;
    //width: 75px;
  }
}
