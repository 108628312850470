.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  // margin-left: 10px;
}

.layer {
  flex: auto;
}

.field {
  margin-top: 10px;
  flex: auto;
}
