.main {
  height: 100%;
}

.indicator {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@import "theme/palette.scss";

.img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.popup_gallery {
  height: 900px;
  width: 900px;
  display: flex;
  padding: 10px;
}

.popup_img {
  flex: auto;
  object-fit: contain;
  max-height: 880px;
}
