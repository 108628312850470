.main {
  height: 100%;
  padding-top: 10px;
  padding-left: 25px;
}

.indicator {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: auto;
}


