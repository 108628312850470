@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.grid {
  flex: auto;
}

.tabs {
  flex: none;
  height: 350px;
  padding-top: 10px;
}

.button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  padding-top: 5px;
}

.header {
  flex: none;
  display: flex;
  height: 35px;
}
.title {
  flex: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 10px;
  padding-top: 5px;
  font-size: 17px !important;
  color: $secondary-text-color;
}
