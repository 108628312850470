@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $background-color;
}

.header {
  flex: none;
}

.container {
  height: 100%;
  flex: auto;
  padding: 10px 10px 0px 5px;
}

.content {
  flex: auto;
}

.footer {
  flex: none;
  height: 25px;
}

.tree {
  display: flex;
  flex-direction: column;

  &_item {
    flex: auto;
    height: 100px;
  }
}
