@import "theme/palette.scss";

.main {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .map {
    flex: auto;
    position: relative;
  }

  .summary {
    flex: none;
    height: 350px;
    margin-top: 10px;
    background-color: $surface-color;
  }
  .loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  .editor {
    height: 100%;
  }

  .text{
    padding-left: 10px;
    font-size: 17px;
  }
  