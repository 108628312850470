@import "theme/palette.scss";

.main{
    height: 100%;
}


.dashboard {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 100px 1fr 1fr  ;
    gap: 10px;
}

.item {
    padding-top: 10px;
    background-color: $surface-color;
}

.header {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
    background-color: $surface-color;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    padding: 10px 10px 10px 10px;
}

.card {
    min-width: 250px;
    flex: 0 0 250x
}

