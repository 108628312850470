.box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.grid {
  height: 100%;
}
