@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
}

.caption {
  font-size: 20px;
  color: $secondary-color;
  margin: 10px 0px 10px 0px;
}

.container1 {
  height: 10px;
  flex: auto;
  max-width: 315px;
}

.container2 {
  flex: auto;
  max-width: 315px;
  margin-left: 20px;
}
