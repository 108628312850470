.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tabs {
  flex: auto;
}

.buttons {
  flex: none;
  height: 50px;
  //margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.indicator {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
