.components-user-panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .user-name {
    flex: auto;
    margin-right: 10px;
    font-size: 16px;
    // font-weight: lighter;
  }

  .image-container {
    flex: none;

    .image {
      border-radius: 50%;
      width: auto;
      height: 50px;
      background-size: cover;
    }
  }

  &-context-menu {
    .dx-icon {
      font-size: 16px;
    }
    .dx-menu-item-text {
      font-size: 14px !important;
      font-weight: lighter;
    }
  }
}
