@import "theme/palette.scss";


.project-activity-map{
    .dx-toolbar-items-container {
        height: 40px;
        background-color:  $menu-color !important;
      }
}

// .dx-toolbar-items-container {
//     height: 40px;
//     background-color:  $menu-color !important;
//   }