.main {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  
  .readlog {
    flex: auto;
  }
  
  .datalog {
    margin-top: 10px;
    flex: auto;
  }
  