.main {
  height: 100%;

  display: flex;
  align-items: center;
  padding-right: 10px;
  justify-content: flex-end;
}

.item {
  flex: none;
  font-size: 10px;
  cursor: pointer;
}
