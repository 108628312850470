@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
}

.grid {
  flex: auto;
}

.gallery {
  flex: none;
  width: 350px;
  padding: 10px;
}
