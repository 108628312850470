  .item {
    display: flex;
    
    &_name {
      flex: auto;
    }
  
    &_icon {
      flex: 25px;
      display: flex;
      justify-content: end;
      align-items: center;
      color: #98C21F;
      font-size: 12px;
      font-weight: bolder;
      letter-spacing: 1px;
    }


  }

  .item_line {
    border: 1px solid #BB86FC ;
  }


  